import { apiFunctions } from '../apiFunction'
import config from '../config'


// const callAPI = async (payload) => {
//     try {
//       const response = await fetch(config.timerAction, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ payload })
//       });
//       if (response.status !== 200) {
//         const error = await response.text();
//         alert(`Error: ${error}`);
//       } else {
//         const data = await response.json();

//         if (data) {
//           console.log(data)
//         } else {
//           alert('Submit Failed!')
//         }
//       }
//     } catch (error) {
//       alert('An unexpected error occurred. Please try again later.');
//     }

//   }

const callAPI = async(payload) => {
    console.log(payload)
    const res=await apiFunctions.updateTaks(payload);
}
  export default callAPI