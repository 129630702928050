import React from 'react'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import useSound from 'use-sound'
import startSfx from '../../sounds/startTimer.mp3'
import pauseSfx from '../../sounds/pauseTimer.mp3'
import callAPI from './../../util/updateStatusAPI'

const TimerDisplay = ({
  wholData,
  timerMode,
  percentage,
  timeLeft,
  isActive,
  setIsActive,
  buttonText,
  setButtonText,
  volume,
  setVolume,
  handleStop,
  token
}) => {
  // console.log("All data is>>", wholData?.id)
  const mobileNumber = localStorage.getItem("mobileNumber")
  const [play] = useSound(startSfx, {
    interrupt: true,
    volume: volume,
  })
  const [pause] = useSound(pauseSfx, {
    interrupt: true,
    volume: volume,
  })

  const handleClick = () => {
    if (timeLeft === '0:00') {
      return null
    }

    if (!isActive) {
      play()
      setIsActive(true)
      setButtonText('STOP')
    } else {
      pause()
      handleStop()
      setIsActive(false)
      setButtonText('START')
    }
    // {"user_login_id":"8884081124","jwt_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiODg4NDA4MTEyNCJ9.fasxD1Ajlihyn95ROizptSBm9PAiOFJK64IICOoa5C0","id":"1707667933","duration":"15"}
    callAPI({ user_login_id: mobileNumber, jwt_token: token, actionIndex: timerMode, event: isActive ? 'stop' : 'start', duration: timeLeft, id: wholData?.id })
  }

  let timesUpMsg = ''
  let timeText = timeLeft === '0:00' ? timesUpMsg : timeLeft
  let textSize = timeLeft === '0:00' ? '12px' : '28px'

  return (
    <>
      <div className="timer" onClick={handleClick}>
        <div className="timer__display">
          <CircularProgressbarWithChildren
            value={percentage}
            text={timeText}
            strokeWidth={4}
            styles={buildStyles({
              // How long animation takes to go from one percentage to another, in seconds
              pathTransitionDuration: 0.5,
              // Colors & Fonts
              pathColor: 'var(--accent-color)',
              textColor: 'var(--text)',
              textSize: textSize,
              fontFamily: 'var(--font-current)',
              trailColor: 'none',
            })}
          >
            <button className="display__start-pause" onClick={handleClick}>{buttonText}</button>
          </CircularProgressbarWithChildren>
        </div>
      </div>
    </>
  )
}

export default TimerDisplay
