import { React, useState } from 'react'
import Button from '../Button/button'
import { CapitalizeFirstLetter } from '../../util/commonFunctions'

const Settings = ({ 
  visible,
  toggleSettingsVisibility,
  timers,
  setTimers,
  closeSettings,
  timerMode,
}) => {

  const colors = {
    default: '#F87070',
    blue: '#70F3F8',
    purple: '#D881F8',
  }

  const fonts = {
    kumbh: `'Kumbh Sans', sans-serif`,
    roboto: `'Roboto Slab', serif`,
    space: `'Space Mono', monospace`,
  }

  const [errorMessage, setErrorMessage] = useState()

  const styles = document.documentElement.style

  const applySettings = (event) => {
    event.preventDefault();

    const newTimers = timers.map((timer) => {
      const formValue = event.target.elements[timer.name];
      if (formValue) {
        const newTimeInSeconds = parseInt(formValue.value, 10) * 60;
        return {
          ...timer,
          initialDuration: parseInt(formValue.value, 10),
          currentTime: newTimeInSeconds,
        };
      }
      return timer;
    });

    setTimers(newTimers);
    closeSettings();
  };

  if (visible) {
    return (
      <div className="preferences preferences--visible">
        <div className="preferences__pane">
          <Button type="close" buttonText="×" toggleVisibility={toggleSettingsVisibility} />
          <h2>Settings {CapitalizeFirstLetter(timerMode)}</h2>
          <form onSubmit={applySettings}>
            <div className="pane__time-settings">
              <h3>Time (Minutes)</h3>
              <div action="" className="time-settings__form">
              {timers.map((timer) => (
                  <div key={timer.id} className="time-setting">
                    <label htmlFor={timer.name}>
                      Set Time for {timer.name}
                    </label>
                    <input
                      type="number"
                      name={timer.name}
                      id={timer.name}
                      min="1"
                      max="60"
                      defaultValue={timer.initialDuration}
                    />
                  </div>
                ))}

              </div>
            </div>

            <Button type="apply" buttonText="Apply" />
          </form>
        </div>
      </div>
    )
  }

  return (null)
}

export default Settings