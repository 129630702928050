import { React, useState } from 'react'
import './login.css'
import { useNavigate } from "react-router-dom"
import CustomDialog from '../../shared/CustomDialog';
import { CustomInput } from "../../shared/InputText";
import { apiFunctions } from '../../apiFunction';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
export default function Login() {
  const [data, setData] = useState({
    name: "",
    mobile: "",
    password: "",
    confirm_password: ""
  });
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  const [errors, setErrors] = useState({});
  const handleChange = ({ name, value }) => {
    setData((prev) => ({ ...prev, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };
  const [mobileNumber, setMobileNumber] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const navigate = useNavigate()

  const handleLogin = async () => {
    try {
      const response = await fetch('https://google.com/authenticate', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ mobileNumber })
      });
      if (response.status !== 200) {
        const error = await response.text();
        setErrorMessage(`Error: ${error}`);
      } else {
        const data = await response.json();

        if (data.verified) {
          localStorage.clear();
          localStorage.setItem('user-token', mobileNumber);
          setTimeout(() => {
            navigate('/');
          }, 500);
        } else {
          setErrorMessage('Verification Failed!')
        }
      }
    } catch (error) {
      setErrorMessage('An unexpected error occurred. Please try again later.');
    }

  }
  const handleLoginDemo = async () => {
    const body = { "mobile": mobileNumber, "password": password }
    const response = await apiFunctions.login(body);
    console.log("Response is>>", response.status)
    if (response.status == '200') {
      console.log("Response is>>", response.data)
      localStorage.clear();
      localStorage.setItem('mobileNumber', mobileNumber);
      localStorage.setItem('jwtToken', response.data.jwt_token);
      setTimeout(() => {
        navigate('/');
      }, 500);
    } else {
      setErrorMessage('Verification Failed!')
    }
  }
  const validateForm = () => {
    const newErrors = {};

    // Validate name (e.g. required and min length)
    if (!data.name) {
      newErrors.name = "Name is required.";
    } else if (data.name.length < 3) {
      newErrors.name = "Name must be at least 3 characters long.";
    }

    // Validate email (e.g. required and must be a valid email)
    if (!data.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      newErrors.email = "Email must be valid.";
    }

    // Validate phone (e.g. required and must be a valid phone number)
    if (!data.phone) {
      newErrors.phone = "Phone number is required.";
    } else if (!/^\d{10}$/.test(data.phone)) {
      newErrors.phone = "Phone number must be 10 digits.";
    }

    // Validate rank (e.g. required)
    if (!data.rank) {
      newErrors.rank = "Rank is required.";
    }

    // Validate password (e.g. required and minimum length)
    if (!data.password) {
      newErrors.password = "Password is required.";
    } else if (data.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long.";
    }

    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    console.log("Signup start")
    console.log("Body is>>", data)
    const response = await apiFunctions.signUp(data);
    console.log("Signup resposne is>>", response)


    setData({
      name: "",
      mobile: "",
      password: "",
      confirm_password: ""
    });

    // Close the dialog
    setOpen(false);
  };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="form-container">
      <div className="logo-container">
        {/* Verify Mobile Number */}
        Login With Mobile Number
      </div>

      <div className="form">
        <div className="form-group">
          <label htmlFor="mnumber">Mobile Number</label>
          <input type="text" id="mnumber" name="mnumber" placeholder="Enter your number" value={mobileNumber} required onChange={e => setMobileNumber(e.target.value)} />
        </div>
        <div className="form-group">
          <label htmlFor="password">Mobile Password</label>
          <div style={{ position: 'relative' }}>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              placeholder="Enter your password"
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
            />
            <FontAwesomeIcon
              icon={showPassword ? faEyeSlash : faEye}
              onClick={togglePasswordVisibility}
              style={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                color: showPassword ? 'red' : 'black',
              }}
            />
          </div>
        </div>
        <button className="form-submit-btn" onClick={handleLoginDemo}>Verify</button>
        <div style={{ flexDirection: 'row' }}>
          <label htmlFor="mnumber">Don't have account</label>
          <span onClick={() => setOpen(true)} style={{ textDecorationLine: 'underline', marginLeft: 10 }}>Resgister now</span>
        </div>
      </div>

      <p className="signup-link">
        {errorMessage}
      </p>

      <CustomDialog
        title={"Add User"}
        visible={open}
        onCancel={onClose}
        loading={loading}
        onSave={handleSave}
      >
        <div className="grid">
          <div className="col-12">
            <label>
              Name
            </label>
            <CustomInput name="name" data={data} onChange={handleChange} />
            {errors.name && (
              <div className="error-message text-xs open-sans">{errors.name}</div>
            )}
          </div>
          <div className="col-12">
            <label>
              Phone
            </label>
            <CustomInput name="mobile" data={data} onChange={handleChange} />
            {errors.phone && (
              <div className="error-message text-xs open-sans">{errors.phone}</div>
            )}
          </div>  <div className="col-12">
            <label>
              Password
            </label>
            <CustomInput name="password" data={data} onChange={handleChange} />
            {errors.password && (
              <div className="error-message text-xs open-sans">{errors.password}</div>
            )}
          </div>
          <div className="col-12">
            <label>
              Confirm Password
            </label>
            <CustomInput name="confirm_password" data={data} onChange={handleChange} />
            {errors.confirmPassword && (
              <div className="error-message text-xs open-sans">{errors.confirmPassword}</div>
            )}
          </div>
        </div>


      </CustomDialog>
    </div>
  )
}
