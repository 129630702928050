import './App.css';
import Controls from './components/Controls/controls'
import TimerDisplay from './components/TimerDisplay/timerdisplay'
import Button from './components/Button/button'
import Settings from './components/Settings/settings'
import { useState, useEffect } from 'react';
import useSound from 'use-sound'
import { useNavigate } from "react-router-dom"
import timesUpSfx from './sounds/timesUp.mp3'
import callAPI from './util/updateStatusAPI'
import { Actions } from './actions/actions';
import { apiFunctions } from './apiFunction';

function Main() {
  const [settingsVisible, setSettingsVisible] = useState(false)
  const [timerMode, setTimerMode] = useState('Device1')   // options: action1, action2
  const [fontPref, setFontPref] = useState('kumbh')         // options: kumbh, roboto, space
  const [accentColor, setAccentColor] = useState('default') // options: default, blue, purple
  const navigate = useNavigate();
  const [timers, setTimers] = useState({});
  const [volume, setVolume] = useState(5);
  const [token, setToken] = useState();

  const controlTimer = (device, action, newValue) => {
    setTimers((prevTimers) => {
      const updatedTimers = { ...prevTimers };
      const timer = { ...updatedTimers[device] };
      switch (action) {
        case 'start':
          timer.isActive = true;
          timer.buttonText = 'STOP';
          break;
        case 'stop':
          timer.isActive = false;
          timer.buttonText = 'START';
          break;
        case 'reset':
          timer.currentTime = timer.initialDuration * 60;
          timer.isActive = false;
          timer.buttonText = 'START';
          break;
        case 'volume':
          timer.volume = newValue;
          break;
        case 'buttonText':
          timer.buttonText = newValue;
          break;
        default:
          break;
      }
      updatedTimers[device] = timer;
      return updatedTimers;
    });
  };

  useEffect(() => {
    getDataFromApi()
  }, []);

  const getDataFromApi = async () => {
    const getMobile = await localStorage.getItem('mobileNumber');
    const getJwtToken = await localStorage.getItem('jwtToken')
    setToken(getJwtToken)
    const data = {
      user_login_id: getMobile, jwt_token: getJwtToken
    }
    const response = await apiFunctions.getUserAction(data)
    const newTimers = {};
    response.data.forEach(action => {
      newTimers[action.device_label] = {
        id: action.id,
        name: action?.device_label,
        initialDuration: action?.duration,
        currentTime: action?.duration * 60,
        isActive: false,
        buttonText: 'START',
        volume: 5,
      };
    });
    setTimers(newTimers);
  }

  const [timesUp] = useSound(timesUpSfx, {
    volume: volume,
  })

  useEffect(() => {
    // Update the current time of each active timer
    const interval = setInterval(() => {
      setTimers((prevTimers) => {
        const updatedTimers = { ...prevTimers };
        Object.keys(updatedTimers).forEach((device) => {
          const timer = updatedTimers[device];
          if (timer.isActive && timer.currentTime > 0) {
            const newCurrentTime = timer.currentTime - 1;
            if (newCurrentTime === 0) {
              timesUp(); // Play sound when timer ends
              controlTimer(device, 'stop');
            }
            updatedTimers[device] = { ...timer, currentTime: newCurrentTime };
          }
        });
        return updatedTimers;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [timesUp, controlTimer]);

  const handleLogout = () => {
    localStorage.clear()
    navigate("/login")
  }

  const toggleSettingsVisibility = (event) => {
    setSettingsVisible(!settingsVisible)
  }

  const formatTimeLeft = (seconds) => {
    return (`${Math.floor(seconds / 60)}:${(seconds % 60 > 9)
      ? seconds % 60
      : '0' + seconds % 60
      }`)
  }

  const handleStop = () => {
    controlTimer(timerMode, 'stop');
    // callAPI({ mobileNumber: localStorage.getItem("mobileNumber"), actionIndex: Actions[timers[timerMode].id].actions, event: "stop", timeVal: timers[timerMode].currentTime });
  };

  const calcPercentage = (timer) => {
    return (timer.currentTime / (timer.initialDuration * 60)) * 100;
  }

  return (
    <div className="timer-app">
      <div className='controls_section'>
        <Controls
          data={Object.values(timers)}
          timerMode={timerMode}
          setTimerMode={setTimerMode}
          volume={volume}
        />
        <Button type="settings" toggleVisibility={toggleSettingsVisibility} />
        <Button type="logout" buttonText='Sign Out  >' handleLogout={handleLogout} />
      </div>
      <TimerDisplay
        wholData={timers[timerMode]}
        token={token}
        key={timerMode}
        timerMode={timerMode}
        percentage={timers[timerMode] ? calcPercentage(timers[timerMode]) : 0}
        timeLeft={timers[timerMode] ? formatTimeLeft(timers[timerMode].currentTime) : '0:00'}
        isActive={timers[timerMode] ? timers[timerMode].isActive : false}
        setIsActive={(newIsActive) => controlTimer(timerMode, newIsActive ? 'start' : 'stop')}
        buttonText={timers[timerMode] ? timers[timerMode].buttonText : 'START'}
        setButtonText={(newButtonText) => controlTimer(timerMode, 'buttonText', newButtonText)}
        volume={timers[timerMode] ? timers[timerMode].volume : 5}
        setVolume={(newVolume) => controlTimer(timerMode, 'volume', newVolume)}
        handleStop={handleStop}
        initialTime={timers[timerMode] ? timers[timerMode].initialDuration * 60 : 0}
      />
      <Settings visible={settingsVisible}
        toggleSettingsVisibility={toggleSettingsVisibility}
        timers={Object.values(timers)}
        setTimers={setTimers}
        closeSettings={toggleSettingsVisibility}
        timerMode={timerMode}
        fontPref={fontPref}
        setFontPref={setFontPref}
        accentColor={accentColor}
        setAccentColor={setAccentColor}
      />
    </div>
  );
}

export default Main;
